@font-face {
  font-family: 'BPG Arial';
  src: url('./fonts/bpg-arial-webfont.eot'); /* IE9 Compat Modes */
  src: url('./fonts/bpg-arial-webfont.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/bpg-arial-webfont.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/bpg-arial-webfont.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('./fonts/bpg-arial-webfont.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/bpg-arial-webfont.svg#bpg_arialregular') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'BPG Algeti';
  src: url('./fonts/bpg-algeti-webfont.eot'); /* IE9 Compat Modes */
  src: url('./fonts/bpg-algeti-webfont.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/bpg-algeti-webfont.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/bpg-algeti-webfont.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('./fonts/bpg-algeti-webfont.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/bpg-algeti-webfont.svg#bpg_algetiregular') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'BPG Rioni Arial';
  src: url('./fonts/bpg-rioni-arial-webfont.eot'); /* IE9 Compat Modes */
  src: url('./fonts/bpg-rioni-arial-webfont.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/bpg-rioni-arial-webfont.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/bpg-rioni-arial-webfont.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('./fonts/bpg-rioni-arial-webfont.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/bpg-rioni-arial-webfont.svg#bpg_rioni_arialregular')
      format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.ttf') format('woff');
}

::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(168, 168, 168);
}

* {
  outline: 0;
  scrollbar-width: thin;
  scrollbar-color: red;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, BPG Rioni Arial,
    'Helvetica Neue', Arial, 'Segoe UI', 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-display: swap;
}

html,
body {
  margin: 0px;
  padding: 0px;
}

a {
  text-decoration: none;
  cursor: pointer;
}

svg {
  vertical-align: middle;
}

button {
  cursor: pointer;
}

button > svg,
button > svg > path,
button > svg > title {
  pointer-events: none;
}

a,
div,
span,
label,
input,
textarea,
select,
button {
  font-size: 16px !important;
  color: #495057;
}

textarea {
  line-height: 20px;
}

html,
body {
  font-size: 16px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.react-grid-Cell {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.rdg-selected {
  border: 0px none;
}

.width-0 {
  width: 0px;
}

.width-100 {
  width: 100%;
}

.width-70 {
  width: 70%;
}

.width-65 {
  width: 65%;
}

.width-60 {
  width: 60%;
}

.width-55 {
  width: 55%;
}

.width-50 {
  width: 50%;
}

.width-45 {
  width: 45%;
}

.width-40 {
  width: 40%;
}

.width-35 {
  width: 35%;
}

.width-30 {
  width: 30%;
}

.width-20 {
  width: 20%;
}

.width-10 {
  width: 10%;
}

.width-25 {
  width: 25%;
}

.width {
  width: 38px;
}

.height {
  height: 38px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-column-stretch {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.space-between {
  justify-content: space-between;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-row-center-end {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.flex-row-center-space {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flex-row-stretch {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.flex-fill {
  flex: 1;
}

.padding {
  padding: 12px;
}

.gap {
  margin: 12px;
}

.gap-left {
  margin-left: 12px;
}

.gap-right {
  margin-right: 12px;
}

.gap-half-right {
  margin-right: 6px;
}

.gap-half-left {
  margin-left: 6px;
}

.gap-top {
  margin-top: 12px;
}

.gap-half-top {
  margin-top: 6px;
}

.gap-half-bottom {
  margin-bottom: 6px;
}

.gap-bottom {
  margin-bottom: 12px;
}

.pad {
  padding: 12px;
}

.pad-left {
  padding-left: 12px;
}

.pad-right {
  padding-right: 12px;
}

.pad-top {
  padding-top: 12px;
}

.pad-bottom {
  padding-bottom: 12px;
}

.pad-half {
  padding: 6px;
}

.bgcolor {
  background-color: whitesmoke;
}

.bottom-border {
  border-bottom: 1px solid #ced4da;
}

.call-later {
  color: #dc3545;
}

.pointer {
  cursor: pointer;
}

.text-blue {
  color: #76a3d2;
}

.text-error {
  color: red;
}

.error {
  color: #dc3545;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.right-0 {
  right: 0px;
}

.opaque-5 {
  opacity: 0.5;
}

.word-break-all {
  word-break: break-all;
}

.overflow-auto {
  overflow: auto;
}

.small-text {
  font-size: 14px !important;
}

.shake {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
